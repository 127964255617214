import React, { useEffect, useState } from 'react';
import { TitleBar } from '../../common';
import { BUTTON, POPUP_MSG, POPUP_TYPE, ROUTES, TITLES } from '../../../constants';
import { Button, PopUpBox } from '../../shared';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDebounce } from '../../../hooks';
import { setLoaderVisibility } from '../../../redux';
import { popup } from '../../../utils';
import {
  getPreScannedPackages,
  vendorSubmitDispatch,
  addPackageToDispatch,
} from '../../../service';
import { useDispatch } from 'react-redux';
import { shipmentDataCompiler } from '../../../utils/dataCompiler/dispatchDataCompiler';
import { FabricDispatchDetail } from './FabricDispatchDetail';
import { BlpTilesDispatch } from '../../common/BlpTilesDispatch';
import { AddDetails, ShipmentDetailsBox } from './CreateDispatch';

export const DispatchDetail = () => {
  const { state } = useLocation(location?.state);
  return (
    <>
      {state?.dyeing_shipment || state?.partner_id?.vendor_type === 'dyeing' ? (
        <FabricDispatchDetail />
      ) : (
        <DispatchDetailNonDyeing />
      )}
    </>
  );
};

const DispatchDetailNonDyeing = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation(location?.state);
  const [reload, setReload] = useState(false);
  const [scanData, setScanData] = useState('');
  const [scanning, setScanning] = useState(false);
  const [validate, setValidate] = useState(false);
  const [update, setUpdate] = useState(false);
  const [tilesToDisplay, setTilesToDisplay] = useState([]);
  const [preScannedPackages, setPreScannedPackages] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [search, setSearch] = useState(false);
  const [shipmentData, setShipmentData] = useState({
    warehouse_id: '',
    warehouse_name: '',
    container_id: '',
    warehouse_options: [],
  });

  const [isCamScannerOpen, setIsCamScannerOpen] = useState(true);
  const isCameraScan = localStorage.getItem('isCameraScan');
  const isCameraScanBool = isCameraScan === 'true';

  const getAlreadyScannedPackages = useDebounce((searchData) => {
    getPreScannedPackages({ data: { id: state?.id, ...searchData } })
      .then((response) => {
        response.data.length > 0
          ? (setPreScannedPackages(shipmentDataCompiler(response.data[0])),
            setShipmentData({
              warehouse_id: response.data[0].warehouse_id.id,
              warehouse_name: response.data[0].warehouse_id.name,
              container_id: response.data[0].container_id || '',
              warehouse_options: response.data[0].warehouse_ids,
            }))
          : setPreScannedPackages(response.message);
        setReload(false);
        dispatch(setLoaderVisibility(false));
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  }, 1000);

  useEffect(() => {
    if (!state) navigate(ROUTES.DISPATCH_DETAILS);
    isCameraScanBool && !isCamScannerOpen ? setScanning(false) : '';
  });

  useEffect(() => {
    dispatch(setLoaderVisibility(true));
    getAlreadyScannedPackages();
  }, []);

  useEffect(() => {
    if (search && searchTerm) {
      const filtered = preScannedPackages.filter((pkg) => {
        return (
          pkg.BLP.toLowerCase().includes(searchTerm.toLowerCase()) || 
          pkg.SKU.toLowerCase().includes(searchTerm.toLowerCase()) || 
          pkg.Batch.toLowerCase().includes(searchTerm.toLowerCase())
        );        
      });
      setSearch(false);
      dispatch(setLoaderVisibility(true));
      if(filtered.length>0)
        setPreScannedPackages(filtered);
      else{
        popup(POPUP_TYPE.ERR, "No relevant packages found")
        setSearch(false);
        setSearchTerm("");
      }
      dispatch(setLoaderVisibility(false));
    } else if (!searchTerm && !Array.isArray(preScannedPackages)) {
      setSearch(false);
      dispatch(setLoaderVisibility(true));
      getAlreadyScannedPackages();
    }
  }, [search, searchTerm]);

  useEffect(() => {
    if (!searchTerm) {
      dispatch(setLoaderVisibility(true));
      getAlreadyScannedPackages();
    }
  }, [searchTerm]);

  useEffect(() => {
    if (reload) {
      dispatch(setLoaderVisibility(true));
      getAlreadyScannedPackages();
    }
  }, [reload]);

  useEffect(() => {
    if (Array.isArray(preScannedPackages)) {
      const initialTiles = preScannedPackages.map((tile) => {
        const isUniformSKU = tile?.SKU_Details?.every(
          (sku) => sku?.SKU === tile?.SKU_Details[0]?.SKU,
        );
        const tileClass = isUniformSKU ? 'blue-shade' : 'pink-shade';
        return { ...tile, tileClass };
      });
      setTilesToDisplay(initialTiles);
    }
  }, [preScannedPackages]);

  useEffect(() => {
    if (scanData) {
      dispatch(setLoaderVisibility(true));
      addPackageToDispatch({
        data: {
          dispatch_id: state?.id,
          package_name: scanData,
          type: 'add',
        },
      }).then((response) => {
        if (response.data.length > 0) popup(POPUP_TYPE.SUCCESS, response.message);
        else if (response.message.includes('flagged'))
          popup(POPUP_TYPE.SUCCESS, `Duplicate Package ${scanData} is Flagged`);
        else popup(POPUP_TYPE.ERR, response.message);
      });
      setScanData('');
      dispatch(setLoaderVisibility(false));
      getAlreadyScannedPackages();
    }
  }, [scanData]);

  return (
    <>
      <TitleBar
        title={[
          { text: TITLES.DISPATCH, route: ROUTES.DISPATCH },
          { text: state?.name, route: ROUTES.NULL },
        ]}
      />
      <ShipmentDetailsBox
        state={state}
        tilesToDisplay={tilesToDisplay}
        setUpdate={setUpdate}
        shipmentData={shipmentData}
        setScanning={setScanning}
        scanning={scanning}
        setIsCamScannerOpen={setIsCamScannerOpen}
        isCameraScanBool={isCameraScanBool}
        setScanData={setScanData}
        preScannedPackages={preScannedPackages}
        setValidate={setValidate}
        setSearchTerm={setSearchTerm}
        searchTerm={searchTerm}
        setSearch={setSearch}
      />
      <div className="bg-white p-3 pt-0 mt-2 package-titles-info border-def-up border-def-down">
        <div className="row custom-row">
          {Array.isArray(preScannedPackages)
            ? tilesToDisplay.map((tile, index) => (
                <BlpTilesDispatch
                  key={index}
                  tile={tile}
                  dispatch_id={state?.id}
                  dispatch_status={state?.dispatch_status}
                  showLoader={scanning}
                  setReload={setReload}
                />
              ))
            : typeof preScannedPackages === 'string' && (
                <div>
                  <h4 className="text-center px-1 py-5">{'Package not found'}</h4>
                </div>
              )}
        </div>
      </div>
      {validate && (
        <PopUpBox openPopUpBox={validate}>
          <DispatchSubmitValidate
            setOpenPopUpBox={setValidate}
            id={state?.id}
            containerNo={shipmentData.container_id}
          />
        </PopUpBox>
      )}
      {update && (
        <PopUpBox openPopUpBox={update}>
          <AddDetails
            setOpenPopUpBox={setUpdate}
            setReload={setReload}
            state={state}
            shipmentData={shipmentData}
          />
        </PopUpBox>
      )}
    </>
  );
};

const DispatchSubmitValidate = ({ setOpenPopUpBox, id, containerNo }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const closePopUp = () => {
    setOpenPopUpBox(false);
  };

  const handleSubmitDispatch = () => {
    setOpenPopUpBox(false);
    dispatch(setLoaderVisibility(true));
    vendorSubmitDispatch({
      data: { dispatch_id: id },
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        popup('success', response.message);
        navigate(ROUTES.DISPATCH);
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  };

  const handleSaveAsDraft = () => {
    popup('success', 'Dispatch Saved as Draft');
    navigate(ROUTES.DISPATCH);
  };

  return (
    <>
      <div className="d-flex m-1">
        <div className="w-25 modal-body py-0 d-flex justify-content-center align-items-center">
          <span className="d-flex justify-content-center align-items-center bg-info badge">!</span>
        </div>
        <div className="w-75 modal-body py-0">
          <h4 className="text-center text-special mb-3">
            <u>NOTE</u>
          </h4>
          <p className="text-center text-special placeholder-color">
            {POPUP_MSG.SUBMIT_DISPATCH_WARNING}
          </p>
        </div>
      </div>
      <hr />
      <div className="pb-3 mt-2 d-flex align-items-center justify-content-end mx-4 modal-btn custom-button">
        <div className="d-flex align-items-center justify-content-between">
          <Button
            clickHandler={handleSubmitDispatch}
            className={`border-0 m-1 ${containerNo === '' || containerNo === false || containerNo === 'N/A' ? 'disabled' : ''} blue-btn d-flex align-items-center justify-content-center py-2 px-3`}
            disabled={containerNo === '' || containerNo === false || containerNo === 'N/A'}>
            {BUTTON.SUBMIT}
          </Button>
          <Button
            clickHandler={handleSaveAsDraft}
            className="border-0 m-1 blue-btn d-flex align-items-center justify-content-center py-2 px-3">
            {BUTTON.SAVE_AS_DRAFT}
          </Button>
          <Button
            clickHandler={closePopUp}
            className="border-0 m-1 blue-btn d-flex align-items-center justify-content-center py-2 px-3">
            {BUTTON.CLOSE}
          </Button>
        </div>
      </div>
    </>
  );
};
