import React from 'react';
import { EditIcon } from '../../../assets/icons';

export const ActionBox = ({
  id,
  lotNo,
  state,
  received_quantity,
  showEdit,
  setPopUp,
  setPopUpData,
  tracking,
}) => {
  return (
    <div className="td-num">
      {state !== 'Done' ? (
        <EditIcon
          onClick={(e) => {
            e.stopPropagation();
            setPopUp(true);
            setPopUpData({ received_quantity, id, lotNo, tracking, showEdit });
          }}
        />
      ) : (
        '-'
      )}
    </div>
  );
};
