import React, { useState, useEffect } from 'react';
import { Button } from '../../shared';
import { popup } from '../../../utils';
import { BUTTON, POPUP_TYPE, ROUTES, SKU_COLUMN } from '../../../constants';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useDebounce } from '../../../hooks';
import { mixBoxPackage, submitSkuMixBoxPackage } from '../../../service/package';
import { setLoaderVisibility } from '../../../redux';
import { packageDataCompiler } from '../../../utils/dataCompiler/packageDataCompiler';
import { DropDownArrow2 } from '../../../assets/icons';
import { Table } from '../../common';

const MixBoxInputTable = () => {
  const [mixBoxData, setMixBoxData] = useState([]);
  const [selectedSKU, setSelectedSKU] = useState({});
  const [qty, setQty] = useState(0);
  const [skuList, setSkuList] = useState([]);
  const [mode, setMode] = useState(0);
  const [availableSku, setAvailableSku] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getMixBoxList = useDebounce(() => {
    mixBoxPackage({
      data: {},
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        setMixBoxData(response.data);
        setAvailableSku(response.data);
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  }, 1000);

  useEffect(() => {
    dispatch(setLoaderVisibility(true));
    getMixBoxList();
  }, []);

  const handleSubmit = () => {
    const data = skuList.map((item) => {
      return {
        id: item.id,
        quantity: item.quantity,
      };
    });
    submitSkuMixBoxPackage({
      data: { data },
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        popup('success', response.message);
        navigate(ROUTES.PACKAGE_BLP_OVERVIEW, {
          state: { data: packageDataCompiler(response.data)[0] },
        });
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
        navigate(ROUTES.PACKAGE);
      });
  };
  const handleListClick = (sku) => {
    setSelectedSKU(sku);
  };
  const handleQtyChange = (e) => {
    let value = e.target.value;
    if (value !== "" && !/^\d+$/.test(value)) {
      return; 
    }
    if (value === "") {
      setQty("");
      return;
    }
    value = +value;
    if (mode === 0 && value <= selectedSKU.quantity) {
      setQty(value);
    } else if (
      mode !== 0 &&
      value !== selectedSKU.quantity &&
      value <= mixBoxData.find((box) => box.id === selectedSKU.id)?.quantity
    ) {
      setQty(value);
      setMode(2);
    }
  };
  
  const addSku = () => {
    if (mode === 0) {
      setSkuList((pre) => {
        return [...pre, { id: selectedSKU.id, name: selectedSKU.name, quantity: qty }];
      });
      setSelectedSKU({});
      setQty(0);
    } else if (mode === 1) {
      setSelectedSKU({});
      setQty(0);
      setMode(0);
    } else {
      setSkuList((pre) => [
        ...pre.filter((sku) => sku.id !== selectedSKU.id),
        {
          id: selectedSKU.id,
          name: selectedSKU.name,
          quantity: qty,
        },
      ]);
      setSelectedSKU({});
      setQty(0);
      setMode(0);
    }
  };
  const handleAction = ({ data, action }) => {
    if (action === 'edit') {
      setSelectedSKU(data);
      setQty(data.quantity);
      setMode(1);
    } else {
      setSkuList((pre) => pre.filter((sku) => sku.id !== data.id));
      setSelectedSKU({});
      setQty(0);
      setMode(0);
    }
  };

  const handleSearch = (e) => {
    const searchValue = e.target.value.toLowerCase();
    const filteredSku = mixBoxData
      .filter((sku) => !skuList.map((list) => list.id).includes(sku.id))
      .filter((sku) => sku.name.toLowerCase().includes(searchValue));
    setAvailableSku(filteredSku.length ? filteredSku : 'No records found');
  };
  useEffect(() => {
    setAvailableSku(mixBoxData.filter((sku) => !skuList.map((list) => list.id).includes(sku.id)));
  }, [skuList]);

  return (
    <div className="data-content h-100">
      <div className="w-100 h-100 editing-container">
        <div className="row h-100 d-flex align-items-center justify-content-center">
          <div className="edit-container py-4 px-lg-5 px-4  overflow-hidden table-new-div bg-white data-box w-100 h-100">
            <div className="d-flex gap-3 w-100 align-items-center">
              <div className="custom-dropdown-4 width-65 ">
                <div className="dropdown w-100">
                  <button
                    className="dropdown-toggle bg-white w-100 d-flex align-items-center justify-content-between"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false">
                    {Object.keys(selectedSKU).length ? selectedSKU.name : 'Select Items'}
                    <DropDownArrow2 />
                  </button>
                  <div className="dropdown-menu p-0 w-100" aria-labelledby="dropdownMenuButton1">
                    <div className="w-100 mt-2 px-1">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search.."
                        onChange={(e) => handleSearch(e)}
                      />
                    </div>
                    <ul className="list-data-area w-100 m-0 p-0">
                      {Array.isArray(availableSku) ? (
                        availableSku.map((sku) => (
                          <li
                            key={sku.id}
                            onClick={() => {
                              handleListClick(sku);
                            }}>
                            <div className="dropdown-item t-wrap d-flex align-items-center justify-content-start">
                              {sku.name}
                            </div>
                          </li>
                        ))
                      ) : (
                        <li>
                          <div className="dropdown-item t-wrap d-flex align-items-center justify-content-start">
                            {availableSku}
                          </div>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
              <input
                className="quantity-input w-20 t-wrap"
                type="number"
                value={qty || ''}
                onChange={(e) => handleQtyChange(e)}
                disabled={!Object.keys(selectedSKU).length}
                placeholder={
                  Object.keys(selectedSKU).length
                    ? `Available Qty : ${mixBoxData.filter((box) => selectedSKU.id === box.id)[0].quantity}`
                    : 'Enter Quantity'
                }
              />
              <Button
                disabled={!qty}
                clickHandler={addSku}
                className={`quantity-input  ${qty ? '' : 'disabled'} `}>
                {mode === 0 ? 'Add' : mode === 1 ? 'cancel' : 'update'}
              </Button>
            </div>
            <div className="d-flex justify-content-end align-items-center mt-5 pt-5">
              <Button
                disabled={!skuList.length}
                className={`border-0 blue-btn me-2 d-flex align-items-center justify-content-center py-2 px-3 ${!skuList.length ? 'disabled' : ''}`}
                clickHandler={handleSubmit}>
                {BUTTON.SUBMIT_SKU}
              </Button>
            </div>
            <div className=" mt-2 mb-2 overflow-scroll h-75">
              <Table className='table-5' columns={SKU_COLUMN} initialData={skuList} func={handleAction}></Table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MixBoxInputTable;
