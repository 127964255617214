import React, { useState } from 'react';
import { Button } from '../../shared';
import { BUTTON } from '../../../constants';
import { DropDownInput } from '../../common';

const PrintLabelModalBulk = ({ onClose, onSubmit, packageData }) => {
  const mixType = ['single', 'mix']
  const sizesOptions = ['12_4', '4_6'];
  const [selectedSize, setSelectedSize] = useState(sizesOptions[0]);
  const [selectedBoxType, setSelectedBoxType] = useState(mixType[0]);

  const handleSubmit = () => {
    const payload = {
      sizes: [selectedSize],
      package_types: [selectedBoxType],
      package_ids: Array.isArray(packageData) ? packageData.map((id) => id.id) : [packageData],
    };
    onSubmit(payload);
    onClose();
  };

  return (
    <div className="modal fade show d-block" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
      <div className="modal-dialog modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title w-100">Print Label</h5>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <label>Select Box Size</label>
              <DropDownInput
                dropdownMenu={sizesOptions}
                value={selectedSize}
                onChange={setSelectedSize}
              />
            </div>
            <div className="form-group">
              <label>Select Box Type</label>
              <DropDownInput
                dropdownMenu={mixType}
                value={selectedBoxType}
                onChange={setSelectedBoxType}
              />
            </div>
          </div>
          <div className="modal-footer">
            <Button
              className={`border-0 blue-btn d-flex align-items-center justify-content-center py-2 px-3`}
              clickHandler={handleSubmit}>
              {BUTTON.SUBMIT}
            </Button>
            <Button
              className="border-0 grey-btn d-flex align-items-center justify-content-center py-2 px-3"
              clickHandler={onClose}>
              {BUTTON.CLOSE}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrintLabelModalBulk;
