import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  MFG_STATUS,
  DROPDOWN,
  TITLES,
  ROUTES,
  POPUP_TYPE,
  DATA_FIELD,
  BUTTON,
} from '../../../../constants';
import { DropDown, SearchBar, TitleBar } from '../../../common';
import { StatusBox } from './StatusBox';
import { GreenTile, RedTile, YellowTile } from './Tiles';
import { vendorLot } from '../../../../service';
import { useDebounce } from '../../../../hooks';
import { setLoaderVisibility } from '../../../../redux';
import { popup, manufacturingDataCompiler, reducedCalc, getSortingArray } from '../../../../utils';
import { Button } from '../../../shared';

export const ManufacturingContent = () => {
  const [mfgData, setMfgData] = useState([]);
  const [sort1, setSort1] = useState('Preference');
  const [sort2, setSort2] = useState('Qty Status');
  const [searchTerm, setSearchTerm] = useState('');
  const [search, setSearch] = useState(false);
  const [sortingArray1, setSortingArray1] = useState([]);
  const [sortingArray2, setSortingArray2] = useState(DROPDOWN.MFG_SORT_2);
  const [sortedData, setSortedData] = useState(mfgData);

  const dispatch = useDispatch();
  const getManufacturingList = useDebounce((data = {}) => {
    vendorLot({
      data,
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        if (response.data.length > 0) {
          setMfgData(manufacturingDataCompiler(response.data));
          if (!sortingArray1.includes(sort1)) {
            setSortingArray1(
              getSortingArray({
                column: { dataField: ['priority'] },
                data: response.data,
                compiler: manufacturingDataCompiler,
              }),
            );
            if (!sortingArray2.includes(sort2)) {
              setSortingArray2(
                getSortingArray({
                  column: { dataField: ['qty_status'] },
                  data: response.data,
                  compiler: manufacturingDataCompiler,
                }),
              );
            }
          }
        } 
        else {
          setMfgData(response.message);
        }
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  }, 1000);

  useEffect(() => {
    dispatch(setLoaderVisibility(true));
    getManufacturingList();
  }, []);

  useEffect(() => {
    setSortedData(mfgData);
  }, [mfgData]);

  useEffect(() => {
    if (sortingArray1.includes(sort1)) {
      let sorted = mfgData.filter((obj) => {
        return obj.priority === sort1.toLowerCase().replaceAll(' ', '_');
      });

      setSort2('Qty Status');
      setSortedData(sorted);
    }
  }, [sort1]);
  useEffect(() => {
    if (sortingArray1.length > 1 || sortingArray2.length > 1) {
      let sorted = [];
      if (sortingArray2.includes(sort2)) {
        setSortedData([]);
        setSort1('Preference');
        sortingArray2.forEach((sort, index) => {
          sorted.push(
            sortedData.filter((obj) => {
              return (
                obj.qty_status ===
                sortingArray2[
                  index - -sortingArray2.indexOf(sort2) >= sortingArray2.length
                    ? index - -sortingArray2.indexOf(sort2) - sortingArray2.length
                    : index - -sortingArray2.indexOf(sort2)
                ]
              );
            }),
          );
        });
        setSortedData(sorted.flat());
      }
    }
  }, [sort2]);
  useEffect(() => {
    let data = {};
    if (search && searchTerm) {
      data = {
        future_lot: searchTerm,
        ['product_id.default_code']: searchTerm,
        search: 'True',
      };
      setSearch(false);
      dispatch(setLoaderVisibility(true));
      getManufacturingList(data);
    } else if (!searchTerm && !Array.isArray(mfgData)) {
      setSearch(false);
      dispatch(setLoaderVisibility(true));
      getManufacturingList(data);
    }
  }, [search, searchTerm]);

  const resetFilter = () => {
    setSort2('Qty Status');
    setSort1('Preference');
    dispatch(setLoaderVisibility(true));
    getManufacturingList();
  };

  useEffect(() => {
    if (!searchTerm) {
      dispatch(setLoaderVisibility(true));
      setMfgData([]);
      getManufacturingList();
    }
  }, [searchTerm]);
  return (
    <>
      <TitleBar title={[{ text: TITLES.MANUFACTURING, route: ROUTES.MANUFACTURING }]} />
      <StatusBox status={MFG_STATUS} />
      <div className="d-flex align-items-center justify-content-start gap-3 w-100">
        <SearchBar setSearchTerm={setSearchTerm} searchTerm={searchTerm} setSearch={setSearch} />
        <div className="w-50  mb-3 filter-btns-div d-flex align-items-center justify-content-start">
          <DropDown dropdownMenu={sortingArray1} value={sort1} setValue={setSort1} />
          <DropDown dropdownMenu={sortingArray2} value={sort2} setValue={setSort2} />
          {(sortingArray2.includes(sort2) || sortingArray1.includes(sort1)) && (
            <Button
              clickHandler={resetFilter}
              className="blue-btn d-flex align-items-center justify-content-center px-3">
              {BUTTON.CLEAR_FILTERS}
            </Button>
          )}
        </div>
      </div>

      <div className="bg-white py-4 px-3 matrix-box">
        <div className="row custom-row">
          {Array.isArray(sortedData) ? (
            sortedData.map((lot) => {
              return lot.ready_qty === 0 ? (
                <RedTile key={lot.id} lot={lot} />
              ) : lot.ready_qty < lot.total_qty ? (
                <YellowTile key={lot.id} lot={lot} />
              ) : (
                <GreenTile key={lot.id} lot={lot} />
              );
            })
          ) : (
            <div>
              <h4 className="px-1 text-center py-5">{mfgData}</h4>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export const getReadyQty = (lot) => {
  const qty = lot?.batch?.reduce((acc, cum) => {
    cum.state === DATA_FIELD.PACKED ? (acc = acc + cum.order_qty) : (acc = acc + 0);
    return acc;
  }, 0);
  return qty;
};
export const getBatchQty = (lot) => {
  const qty = reducedCalc(lot?.batch, DATA_FIELD.ORDER_QTY);
  return qty;
};
