import React from 'react';
import { Packages, EditIcon, DeleteRedIcon } from '../assets/icons';
import { ActionBox } from '../components/pages/receipt/ActionBox';
import { ActionBox2 } from '../components/pages/dispatch/ActionBox2';
import { ActionBox1 } from '../components/pages/manufacturing/fabricManufacturing/ActionBox1';
import { capitalize } from '../utils';

export const PO_COLUMN = [
  {
    name: '',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: [''],
  },
  {
    name: '*',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    isCustom: true,
    dataField: ['invoice_status', 'bill_amount', 'name', 'currency_code', 'id'],
    customFormatter: ({ invoice_status, bill_amount, name, currency_code, id, func }) => {
      return (
        <div className=" w-100 h-100 d-flex">
          <input
            className={
              capitalize(invoice_status) !== 'To invoice' ? 'disable-check' : 'enable-check'
            }
            disabled={capitalize(invoice_status) !== 'To invoice'}
            type="checkbox"
            onChange={() => func.addPOToBill({ id, bill_amount, currency_code, name })}
            checked={func.checked(id)}
          />
        </div>
      );
    },
  },
  {
    name: 'Reference',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['name'],
    sort: true,
    valueType: 'String',
    canBeFiltered: true,
  },{
    name: 'Lot',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['lot'],
    valueType: 'String',
    canBeFiltered: true,
  },

  {
    name: 'Scheduled Date/Time',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['schedule_date'],
    canBeFiltered: false,
  },
  {
    name: 'Source Document',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['origin'],
    canBeFiltered: false,
  },
  {
    name: 'Bill Status',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['invoice_status'],
  },

  {
    name: 'Qty Status',
    classNameTH: '',
    isCustom: true,
    classNameTD: 'option-td',
    dataField: ['qty_status'],
    canBeFiltered: true,
    customFormatter: ({ qty_status }) => {
      return (
        <div
          className={`col-${qty_status === 'Pending' ? 'waiting' : qty_status === 'Full' ? 'received' : 'dispatched'} pills col-received d-flex ps-2 justify-content-center status-pills`}>
          <p>{qty_status}</p>
        </div>
      );
    },
  },
];
export const BILL_GENERATION_COLUMN = [
  {
    name: '',
    classNameTH: 'product-td',
    classNameTD: 'product-td text-center',
    dataField: [''],
  },
  {
    name: 's/n',
    classNameTH: 'product-td',
    isCustom: true,
    classNameTD: 'product-td text-center',
    dataField: ['rowIndex'],
    customFormatter: ({ rowIndex }) => {
      return (
        <div className="d-flex justify-content-start mt-3 align-items-center">
          <p>{rowIndex + 1}</p>
        </div>
      );
    },
  },
  {
    name: 'Ref',
    classNameTH: 'product-td',
    classNameTD: 'product-td text-center',
    dataField: ['name'],
  },
  {
    name: 'Amount',
    classNameTH: 'quantity-td',
    classNameTD: 'quantity-td text-center',
    isCustom: true,
    valueType: 'Number',
    dataField: ['amount', 'currency_code'],
    customFormatter: ({ amount, currency_code }) => {
      return (
        <div className="d-flex justify-content-start mt-3 align-items-center">
          <p>{`${amount.toFixed(2)} ${currency_code}`}</p>
        </div>
      );
    },
  },
  {
    name: 'Action',
    classNameTH: 'quantity-td',
    classNameTD: 'quantity-td text-center',
    isCustom: true,
    valueType: 'Number',
    dataField: ['id'],
    customFormatter: ({ func, id }) => {
      return (
        <div onClick={() => func(id)} className="d-flex justify-content-start">
          <DeleteRedIcon />
        </div>
      );
    },
  },
];

export const PO_DETAILS_COLUMN = [
  {
    name: '',
    classNameTH: 'product-td',
    classNameTD: 'product-td text-center',
    dataField: [''],
  },
  {
    name: 'Product',
    classNameTH: 'product-td',
    classNameTD: 'product-td text-center',
    dataField: ['product'],
  },
  {
    name: 'Lot No',
    classNameTH: 'product-td',
    classNameTD: 'product-td text-center',
    dataField: ['lot_no'],
  },
  {
    name: 'Quantity',
    classNameTH: 'quantity-td',
    classNameTD: 'quantity-td text-center',
    sort: true,
    valueType: 'Number',
    dataField: ['qty'],
  },
  {
    name: 'Delivered',
    classNameTH: 'received-td',
    classNameTD: 'received-td text-center',
    dataField: ['received'],
  },
  {
    name: 'Rolls',
    classNameTH: 'received-td',
    classNameTD: 'received-td text-center',
    dataField: ['rolls'],
  },
  {
    name: 'Undelivered',
    classNameTH: 'undelivered-td',
    classNameTD: 'undelivered-td text-center',
    sort: true,
    valueType: 'Number',
    dataField: ['balance'],
  },
  {
    name: 'Billed',
    classNameTH: 'billed-td',
    classNameTD: 'billed-td text-center',
    sort: true,
    valueType: 'Number',
    dataField: ['billed'],
  },
  {
    name: 'UoM',
    classNameTH: 'uom-td',
    classNameTD: 'uom-td text-center',
    dataField: ['uom'],
  },
  {
    name: 'Unit Price',
    classNameTH: 'unit-price-td',
    classNameTD: 'unit-price-td text-center',
    sort: true,
    valueType: 'Number',
    dataField: ['unit_price'],
  },
  {
    name: 'Taxes',
    classNameTH: 'taxes-td',
    classNameTD: 'taxes-td text-center',
    dataField: ['tax'],
  },
];

export const RECEIPT_COLUMN = [
  {
    name: '',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: [''],
  },
  {
    name: 'Reference',
    classNameTH: 'option-td',
    classNameTD: 'checkbox-td text-center',
    dataField: ['reference'],
    sort: true,
    valueType: 'String',
    canBeFiltered: true,
  },
  {
    name: 'Lot No',
    classNameTH: 'option-td',
    classNameTD: 'checkbox-td text-center',
    dataField: ['lot'],
    sort: true,
    valueType: 'String',
    canBeFiltered: true,
  },
  {
    name: 'Container No',
    classNameTH: 'option-td',
    classNameTD: 'checkbox-td text-center',
    dataField: ['container'],
    valueType: 'String',
    canBeFiltered: true,
  },
  {
    name: 'Contact',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['contact'],
    canBeFiltered: false,
  },
  {
    name: 'Scheduled Date',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['schedule_date'],
    canBeFiltered: false,
  },
  {
    name: 'Source Document',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    sort: true,
    valueType: 'String',
    dataField: ['source_document'],
    canBeFiltered: true,
  },
  {
    name: 'Status',
    classNameTH: 'option-td',
    isCustom: true,
    classNameTD: 'option-td',
    dataField: ['status'],
    canBeFiltered: true,
    customFormatter: ({ status }) => {
      const statusClass = status === 'Done' ? 'received' : 'dispatched';
      return (
        <div className={`pills col-${statusClass} d-flex ps-2 justify-content-center status-pills`}>
          <p>{status}</p>
        </div>
      );
    },
  },
];

export const RECEIPT_INNER_COLUMN = [
  {
    name: '',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: [''],
  },
  {
    name: 'Product',
    classNameTH: 'product-td',
    classNameTD: 'product-td text-center',
    dataField: ['product'],
  },
  {
    name: 'Lot No',
    classNameTH: 'lotNo-td',
    classNameTD: 'lotNo-td text-center',
    sort: true,
    valueType: 'String',
    dataField: ['lotNo'],
  },
  {
    name: 'Demand Quantity',
    classNameTH: 'demand-quantity-td',
    classNameTD: 'demand-quantity-td text-center',
    sort: true,
    valueType: 'Number',
    dataField: ['demand_quantity'],
  },
  {
    name: 'Received Quantity',
    classNameTH: 'received-quantity-td',
    classNameTD: 'received-quantity-td text-center',
    sort: true,
    valueType: 'Number',
    dataField: ['received_quantity'],
  },
  {
    name: 'Rolls',
    classNameTH: 'received-quantity-td',
    classNameTD: 'received-quantity-td text-center',
    sort: true,
    valueType: 'String',
    dataField: ['rolls'],
  },
  {
    name: 'Unit',
    classNameTH: 'unit-td',
    classNameTD: 'unit-td text-center',
    dataField: ['unit'],
  },
  {
    name: 'Action',
    classNameTH: 'actions-td',
    classNameTD: 'actions-td text-center',
    dataField: ['received_quantity', 'lotNo', 'showEdit', 'id', 'tracking', 'state'],
    isCustom: true,
    customFormatter: ({
      showEdit,
      state,
      id,
      lotNo,
      received_quantity,
      popUp,
      setPopUp,
      setPopUpData,
      tracking,
    }) => {
      return (
        <>
          {
            <ActionBox
              id={id}
              state={state}
              setPopUpData={setPopUpData}
              showEdit={showEdit}
              received_quantity={received_quantity}
              popUp={popUp}
              setPopUp={setPopUp}
              lotNo={lotNo}
              tracking={tracking}
            />
          }
        </>
      );
    },
  },
];

export const FABRIC_DISPATCH_INNER_COLUMN = [
  {
    name: '',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: [''],
  },
  {
    name: 'Product',
    classNameTH: 'product-td',
    classNameTD: 'product-td text-center',
    dataField: ['product'],
  },
  {
    name: 'LotNo',
    classNameTH: 'lotno-td',
    classNameTD: 'lotno-td text-center',
    sort: true,
    valueType: 'String',
    dataField: ['lotno'],
  },
  {
    name: 'Demand Quantity',
    classNameTH: 'demand-quantity-td',
    classNameTD: 'demand-quantity-td text-center',
    sort: true,
    valueType: 'Number',
    dataField: ['demand_quantity'],
  },
  {
    name: 'Quantity',
    classNameTH: 'producing-quantity-td',
    classNameTD: 'producing-quantity-td text-center',
    sort: true,
    valueType: 'Number',
    dataField: ['producing_quantity'],
  },
  {
    name: 'Rolls',
    classNameTH: 'received-quantity-td',
    classNameTD: 'received-quantity-td text-center',
    sort: true,
    valueType: 'String',
    dataField: ['rolls'],
  },
  {
    name: 'Unit',
    classNameTH: 'unit-td',
    classNameTD: 'unit-td text-center',
    dataField: ['unit'],
  },
  {
    name: 'Action',
    classNameTH: 'actions-td',
    classNameTD: 'actions-td text-center',
    dataField: ['move_id', 'producing_quantity', 'demand_quantity'],
    isCustom: true,
    customFormatter: ({
      id,
      demand_quantity,
      producing_quantity,
      move_id,
      popUp,
      setPopUp,
      setPopUpData,
    }) => {
      return (
        <>
          <ActionBox2
            id={id}
            setPopUpData={setPopUpData}
            demand_quantity={demand_quantity}
            producing_quantity={producing_quantity}
            popUp={popUp}
            setPopUp={setPopUp}
            move_id={move_id}
          />
        </>
      );
    },
  },
];

export const YARN_DISPATCH_INNER_COLUMN = [
  {
    name: '',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: [''],
  },
  {
    name: 'Product',
    classNameTH: 'product-td',
    classNameTD: 'product-td text-center',
    dataField: ['product'],
  },
  {
    name: 'LotNo',
    classNameTH: 'lotno-td',
    classNameTD: 'lotno-td text-center',
    sort: true,
    valueType: 'String',
    dataField: ['lotno'],
  },
  {
    name: 'Demand Quantity',
    classNameTH: 'demand-quantity-td',
    classNameTD: 'demand-quantity-td text-center',
    sort: true,
    valueType: 'Number',
    dataField: ['demand_quantity'],
  },
  {
    name: 'Done Quantity',
    classNameTH: 'producing-quantity-td',
    classNameTD: 'producing-quantity-td text-center',
    sort: true,
    valueType: 'Number',
    dataField: ['producing_quantity'],
  },
  {
    name: 'Rolls',
    classNameTH: 'producing-quantity-td',
    classNameTD: 'producing-quantity-td text-center',
    sort: true,
    valueType: 'String',
    dataField: ['rolls'],
  },
  {
    name: 'Unit',
    classNameTH: 'unit-td',
    classNameTD: 'unit-td text-center',
    dataField: ['unit'],
  },
  {
    name: 'Action',
    classNameTH: 'actions-td',
    classNameTD: 'actions-td text-center',
    dataField: [
      'producing_quantity',
      'lotno',
      'move_id',
      'tracking',
      'unit',
      'demand_quantity',
      'product',
    ],
    isCustom: true,
    customFormatter: ({
      unit,
      move_id,
      product,
      demand_quantity,
      tracking,
      popUp,
      setPopUp,
      setPopUpData,
      lotno,
      producing_quantity,
    }) => {
      return (
        <ActionBox2
          unit={unit}
          move_id={move_id}
          product={product}
          setPopUpData={setPopUpData}
          demand_quantity={demand_quantity}
          tracking={tracking}
          popUp={popUp}
          setPopUp={setPopUp}
          lotno={lotno}
          producing_quantity={producing_quantity}
        />
      );
    },
  },
];

export const PACKAGE_COLUMN = [
  {
    name: '',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: [''],
  },
  {
    name: 'Package No',
    classNameTH: 'package-no-th',
    classNameTD: 'package-no-td text-center',
    dataField: ['package_no'],
    sort: true,
    valueType: 'String',
  },
  {
    name: 'Box Count',
    classNameTH: 'box-count-th',
    classNameTD: 'box-count-td text-center',
    dataField: ['box_count'],
    sort: true,
    valueType: 'Number',
  },
  {
    name: 'Packaging Date',
    classNameTH: 'packaging-date-th',
    classNameTD: 'packaging-date-td',
    dataField: ['packaging_date'],
  },
  {
    name: 'Dispatch Date',
    classNameTH: 'dispatch-date-th',
    classNameTD: 'dispatch-date-td',
    dataField: ['dispatch_date'],
  },
  {
    name: 'Status',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['status'],
    customFormatter: ({ status }) => {
      return <div>{status}</div>;
    },
  },
];

export const DISPATCH_COLUMN = [
  {
    name: '',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: [''],
  },
  {
    name: 'Shipment No',
    classNameTH: 'option-th',
    classNameTD: 'option-td',
    sort: true,
    valueType: 'String',
    dataField: ['shipment_no'],
  },
  {
    name: 'Lot No',
    classNameTH: 'option-th',
    classNameTD: 'option-td',
    valueType: 'String',
    sort: true,
    dataField: ['lot'],
  },
  {
    name: 'Container No',
    classNameTH: 'option-th',
    classNameTD: 'option-td',
    valueType: 'String',
    dataField: ['container'],
  },
  {
    name: 'Delivery Contact',
    classNameTH: 'option-th',
    classNameTD: 'option-td',
    dataField: ['delivery_contact'],
  },
  {
    name: 'Source',
    classNameTH: 'option-th',
    classNameTD: 'option-td',
    sort: true,
    valueType: 'String',
    dataField: ['origin'],
  },
  {
    name: 'Shipment Date',
    classNameTH: 'option-th',
    classNameTD: 'option-td',
    dataField: ['shipment_date'],
  },
  {
    name: 'Box Count',
    classNameTH: 'option-th',
    classNameTD: 'option-td',
    dataField: ['box_count'],
    sort: true,
    valueType: 'Number',
  },
  {
    name: 'Dispatch Status',
    classNameTH: '',
    isCustom: true,
    classNameTD: 'option-td',
    dataField: ['status'],

    customFormatter: ({ status }) => {
      const statusClass =
        status === 'Dispatched' ? 'received' : status === 'Received' ? 'dispatched' : 'waiting';
      return (
        <div className={`pills col-${statusClass} d-flex ps-2 justify-content-center status-pills`}>
          <p>{status}</p>
        </div>
      );
    },
  },
  {
    name: 'State',
    classNameTH: '',
    isCustom: true,
    classNameTD: 'option-td',
    dataField: ['state'],
    customFormatter: ({ state }) => {
      const statusClass =
        state === 'Done' ? 'received' : state === 'Assigned' ? 'dispatched' : 'waiting';
      return (
        <div className={`pills col-${statusClass} d-flex ps-2 justify-content-center status-pills`}>
          <p>{state}</p>
        </div>
      );
    },
  },
];

export const BILLS_COLUMN = [
  {
    name: '',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: [''],
  },

  {
    name: 'Invoice',
    classNameTH: 'invoice-th',
    classNameTD: 'invoice-td text-center',
    dataField: ['name'],
    canBeFiltered: true,
  },
  {
    name: 'Lot',
    classNameTH: 'invoice-th',
    classNameTD: 'invoice-td text-center',
    dataField: ['lot'],
    canBeFiltered: true,
  },
  {
    name: 'Source',
    classNameTH: 'invoice-th',
    classNameTD: 'invoice-td text-center',
    dataField: ['source'],
    sort: true,
    valueType: 'String',
    canBeFiltered: true,
  },
  {
    name: 'Invoice Date',
    classNameTH: 'invoice-date-th',
    classNameTD: 'invoice-date-td text-center',
    dataField: ['invoice_date'],
    canBeFiltered: true,
  },
  {
    name: 'Due Date',
    classNameTH: 'due-date-th',
    classNameTD: 'due-date-td text-center',
    dataField: ['invoice_date_due'],
    canBeFiltered: true,
  },
  {
    name: 'Amount Due',
    classNameTH: 'amount-due-th',
    classNameTD: 'amount-due-td text-center',
    dataField: ['amount_residual_signed'],
    sort: true,
    valueType: 'Number',
    canBeFiltered: true,
  },
  {
    name: 'Status',
    classNameTH: 'option-td',
    classNameTD: 'option-td text-center',
    dataField: ['state'],
    isCustom: true,
    canBeFiltered: true,
    customFormatter: ({ state }) => {
      const statusClass = state === 'Draft' ? 'dispatched' : 'received';
      return (
        <div className={`pills col-${statusClass} d-flex ps-2 justify-content-center status-pills`}>
          <p>{state}</p>
        </div>
      );
    },
  },
];

export const BILLS_DETAILS_COLUMN = [
  {
    name: '',
    classNameTH: 'product-td',
    classNameTD: 'product-td text-center',
    dataField: [''],
  },
  {
    name: 'Product',
    classNameTH: 'product-td',
    classNameTD: 'product-td text-center',
    dataField: ['product'],
  },
  {
    name: 'Lot No',
    classNameTH: 'product-td',
    classNameTD: 'product-td text-center',
    dataField: ['lot_no'],
  },
  {
    name: 'Quantity',
    classNameTH: 'quantity-td',
    classNameTD: 'quantity-td text-center',
    dataField: ['qty'],
    sort: true,
    valueType: 'Number',
  },
  {
    name: 'UoM',
    classNameTH: 'uom-td',
    classNameTD: 'uom-td text-center',
    dataField: ['uom'],
  },
  {
    name: 'Price',
    classNameTH: 'price-td',
    classNameTD: 'price-td text-center',
    dataField: ['unit_price'],
    sort: true,
    valueType: 'Number',
  },
  {
    name: 'Tax',
    classNameTH: 'tax-td',
    classNameTD: 'tax-td text-center',
    dataField: ['tax'],
  },
  {
    name: 'SubTotal',
    classNameTH: 'tax-excl-td',
    classNameTD: 'tax-excl-td text-center',
    dataField: ['subtotal'],
  },
];
export const BATCH_COLUMN = [
  {
    name: 'Batch No',
    classNameTh: '',
    classNameTd: '',
    isCustom: false,
    dataField: ['name'],
    hideIfDye: false,
  },  {
    name: 'Lot',
    classNameTh: '',
    classNameTd: '',
    isCustom: false,
    dataField: ['lot'],
    hideIfDye: false,
  },

  {
    name: 'Order QTY',
    classNameTh: '',
    classNameTd: '',
    isCustom: false,
    dataField: ['order_qty'],
    hideIfDye: false,
  },
  {
    name: 'Cut QTY',
    classNameTh: '',
    classNameTd: '',
    isCustom: false,
    dataField: ['cut_qty'],
    hideIfDye: false,
  },
  {
    name: 'Cut Date',
    classNameTh: '',
    classNameTd: '',
    isDate: true,
    dataField: ['cut_date'],
    hideIfDye: false,
  },
  {
    name: 'Sew QTY',
    classNameTh: '',
    classNameTd: '',
    isCustom: false,
    dataField: ['sew_qty'],
    hideIfDye: false,
  },
  {
    name: 'Sew Date',
    classNameTh: '',
    classNameTd: '',
    isDate: true,
    dataField: ['sew_date'],
    hideIfDye: false,
  },
  // {
  //   name: 'Dye QTY',
  //   classNameTh: '',
  //   classNameTd: '',
  //   isCustom: false,
  //   dataField: ['dye_qty'],
  //   hideIfDye: true,
  // },
  // {
  //   name: 'Dye Date',
  //   classNameTh: '',
  //   classNameTd: '',
  //   isDate: true,
  //   dataField: ['dye_date'],
  //   hideIfDye: true,
  // },
  {
    name: 'Packed Qty',
    classNameTh: '',
    classNameTd: '',
    isCustom: false,
    dataField: ['pack_qty'],
    hideIfDye: true,
  },
  {
    name: 'Packed Date',
    classNameTh: '',
    classNameTd: '',
    isDate: true,
    dataField: ['pack_date'],
    hideIfDye: true,
  },
  {
    name: 'Completed',
    classNameTh: '',
    classNameTd: '',
    isCustom: true,
    hideIfDye: false,
    dataField: ['state'],
    customFormatter: ({ state }) => {
      return (
        <div
          className={`${state === 'packed' ? 'green' : 'orange'}-active w-auto d-flex align-items-center justify-content-center`}>
          {state === 'packed' ? 'completed' : 'In Progress'}
        </div>
      );
    },
  },
  {
    name: 'Action',
    classNameTh: '',
    classNameTd: '',
    isClickable: true,
    isCustom: true,
    dataField: ['state'],
    customFormatter: ({ state }) => {
      return state === 'packed' ? <Packages width={'18'} height={'18'} /> : <EditIcon />;
    },
  },
];

export const BLP_COLUMNS = [
  {
    name: 'No',
    classNameTh: '',
    classNameTd: '',
    dataField: ['id'],
  },
  {
    name: 'BLP No',
    classNameTh: '',
    classNameTd: '',
    dataField: ['name'],
  },
];

export const PRODUCTION_COLUMN = [
  {
    name: '',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: [''],
  },
  {
    name: 'Reference',
    classNameTH: 'option-th',
    classNameTD: 'option-td',
    sort: true,
    valueType: 'String',
    dataField: ['shipment_no'],
  },
  {
    name: 'Lot No',
    classNameTH: 'option-th',
    classNameTD: 'option-td',
    valueType: 'String',
    sort: true,
    dataField: ['lot'],
  },
  {
    name: 'Contact',
    classNameTH: 'option-th',
    classNameTD: 'option-td',
    dataField: ['contact'],
  },
  {
    name: 'Source',
    classNameTH: 'option-th',
    classNameTD: 'option-td',
    sort: true,
    valueType: 'String',
    dataField: ['origin'],
  },
  {
    name: 'Date',
    classNameTH: 'option-th',
    classNameTD: 'option-td',
    dataField: ['shipment_date'],
  },
  {
    name: 'Priority',
    classNameTH: 'option-th',
    classNameTD: 'option-td',
    dataField: ['priority'],
  },
  // {
  //   name: 'Manufacturing Status',
  //   classNameTH: '',
  //   isCustom: true,
  //   classNameTD: 'option-td',
  //   dataField: ['status'],
  //   customFormatter: ({ status }) => {
  //     const statusClass = status === 'done' ? 'received' : 'waiting';
  //     return (
  //       <div className={`pills col-${statusClass} d-flex ps-2 justify-content-center status-pills`}>
  //         <p>{status}</p>
  //       </div>
  //     );
  //   },
  // },
  {
    name: 'State',
    classNameTH: '',
    isCustom: true,
    classNameTD: 'option-td',
    dataField: ['state'],
    customFormatter: ({ state }) => {
      const statusClass = state === 'Done' ? 'received' : 'waiting';
      return (
        <div className={`pills col-${statusClass} d-flex ps-2 justify-content-center status-pills`}>
          <p>{state}</p>
        </div>
      );
    },
  },
];
export const FABRIC_PRODUCTION_DETAILS_COLUMN = [
  {
    name: '',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: [''],
  },
  {
    name: 'Product',
    classNameTH: 'product-td',
    classNameTD: 'product-td text-center',
    dataField: ['product'],
  },
  {
    name: 'LotNo',
    classNameTH: 'lotno-td',
    classNameTD: 'lotno-td text-center',
    sort: true,
    valueType: 'String',
    dataField: ['lotno'],
  },
  {
    name: 'Demand Quantity',
    classNameTH: 'demand-quantity-td',
    classNameTD: 'demand-quantity-td text-center',
    sort: true,
    valueType: 'Number',
    dataField: ['demand_quantity'],
  },
  {
    name: 'Produced Quantity',
    classNameTH: 'producing-quantity-td',
    classNameTD: 'producing-quantity-td text-center',
    sort: true,
    valueType: 'Number',
    dataField: ['producing_quantity'],
  },
  {
    name: 'Rolls',
    classNameTH: 'received-quantity-td',
    classNameTD: 'received-quantity-td text-center',
    sort: true,
    valueType: 'String',
    dataField: ['rolls'],
  },
  {
    name: 'Unit',
    classNameTH: 'unit-td',
    classNameTD: 'unit-td text-center',
    dataField: ['unit'],
  },
  {
    name: 'Action',
    classNameTH: 'actions-td',
    classNameTD: 'actions-td text-center',
    dataField: [
      'demand_quantity',
      'material',
      'subcontract',
      'lotno',
      'producing_quantity',
      'move_id',
      'tracking',
    ],

    isCustom: true,
    customFormatter: ({
      popUp,
      setPopUp,
      setPopUpData,
      demand_quantity,
      lotno,
      producing_quantity,
      subcontract,
      move_id,
      tracking,
      material,
    }) => {
      return (
        <ActionBox1
          setPopUpData={setPopUpData}
          popUp={popUp}
          setPopUp={setPopUp}
          data={{
            tracking,
            material,
            subcontract,
            demand_quantity,
            lotno,
            producing_quantity,
            move_id,
          }}
        />
      );
    },
  },
];

export const REPORTS_COLUMN = [
  {
    name: '',
    classNameTH: 'option-th',
    classNameTD: 'option-td',
    dataField: [''],
  },
  {
    name: 'PO',
    classNameTH: 'option-th',
    classNameTD: 'option-td',
    dataField: ['po'],
  },
  {
    name: 'Lot',
    classNameTH: 'option-th',
    classNameTD: 'option-td',
    dataField: ['lot'],
  },
  {
    name: 'PF Name',
    classNameTH: 'option-th',
    classNameTD: 'option-td',
    dataField: ['pf_name'],
  },
  {
    name: 'PF Color',
    classNameTH: 'option-th',
    classNameTD: 'option-td',
    dataField: ['pf_color'],
  },
  {
    name: 'PF Size',
    classNameTH: 'option-th',
    classNameTD: 'option-td',
    dataField: ['pf_size'],
  },
  {
    name: 'PF Ordered Qty',
    classNameTH: 'option-th',
    classNameTD: 'option-td',
    dataField: ['pf_ordered_qty'],
  },
  {
    name: 'PF Produced Qty',
    classNameTH: 'option-th',
    classNameTD: 'option-td',
    dataField: ['pf_produced_qty'],
  },
  {
    name: 'PF Produced Date',
    classNameTH: 'option-th',
    classNameTD: 'option-td',
    dataField: ['pf_produced_date'],
  },
  {
    name: 'RIB Name',
    classNameTH: 'option-th',
    classNameTD: 'option-td',
    dataField: ['rib_name'],
  },
  {
    name: 'RIB Ordered Qty',
    classNameTH: 'option-th',
    classNameTD: 'option-td',
    dataField: ['rib_ordered_qty'],
  },
  {
    name: 'RIB Produced Qty',
    classNameTH: 'option-th',
    classNameTD: 'option-td',
    dataField: ['rib_produced_qty'],
  },
  {
    name: 'RIB Produced Date',
    classNameTH: 'option-th',
    classNameTD: 'option-td',
    dataField: ['rib_produced_date'],
  },
  {
    name: 'IP Name',
    classNameTH: 'option-th',
    classNameTD: 'option-td',
    dataField: ['ip_name'],
  },
  {
    name: 'IP Ordered Qty',
    classNameTH: 'option-th',
    classNameTD: 'option-td',
    dataField: ['ip_ordered_qty'],
  },
  {
    name: 'IP Cut Qty',
    classNameTH: 'option-th',
    classNameTD: 'option-td',
    dataField: ['ip_cut_qty'],
  },
  {
    name: 'IP Cut Date',
    classNameTH: 'option-th',
    classNameTD: 'option-td',
    dataField: ['ip_cut_date'],
  },
  {
    name: 'IP Sew Qty',
    classNameTH: 'option-th',
    classNameTD: 'option-td',
    dataField: ['ip_sew_qty'],
  },
  {
    name: 'IP Sew Date',
    classNameTH: 'option-th',
    classNameTD: 'option-td',
    dataField: ['ip_sew_date'],
  },
  {
    name: 'FP Name',
    classNameTH: 'option-th',
    classNameTD: 'option-td',
    dataField: ['fp_name'],
  },
  {
    name: 'FP Ordered Qty',
    classNameTH: 'option-th',
    classNameTD: 'option-td',
    dataField: ['fp_ordered_qty'],
  },
  {
    name: 'FP Cut Qty',
    classNameTH: 'option-th',
    classNameTD: 'option-td',
    dataField: ['fp_cut_qty'],
  },
  {
    name: 'FP Cut Date',
    classNameTH: 'option-th',
    classNameTD: 'option-td',
    dataField: ['fp_cut_date'],
  },
  {
    name: 'FP Sew Qty',
    classNameTH: 'option-th',
    classNameTD: 'option-td',
    dataField: ['fp_sew_qty'],
  },
  {
    name: 'FP Sew Date',
    classNameTH: 'option-th',
    classNameTD: 'option-td',
    dataField: ['fp_sew_date'],
  },
  {
    name: 'FP Packed Qty',
    classNameTH: 'option-th',
    classNameTD: 'option-td',
    dataField: ['fp_packed_qty'],
  },
  {
    name: 'FP Packed Date',
    classNameTH: 'option-th',
    classNameTD: 'option-td',
    dataField: ['ip_packed_date'],
  },
];

export const REPORTS_DATA = [
  {
    po: 'PO1234',
    lot: 'LOT001',
    pf_name: 'Product A',
    pf_color: 'Red',
    pf_size: 'M',
    pf_ordered_qty: 500,
    pf_produced_qty: 450,
    pf_produced_date: '2023-08-01',
    rib_name: 'Rib A',
    rib_ordered_qty: 300,
    rib_produced_qty: 290,
    rib_produced_date: '2023-08-02',
    ip_name: 'IP A',
    ip_ordered_qty: 200,
    ip_cut_qty: 180,
    ip_cut_date: '2023-08-05',
    ip_sew_qty: 175,
    ip_sew_date: '2023-08-10',
    fp_name: 'FP A',
    fp_ordered_qty: 400,
    fp_packed_qty: 400,
    fp_cut_qty: 350,
    fp_cut_date: '2023-08-06',
    fp_sew_qty: 340,
    ip_packed_qty: 330,
    ip_packed_date: '2023-08-12',
  },
  {
    po: 'PO1235',
    lot: 'LOT002',
    pf_name: 'Product B',
    pf_color: 'Blue',
    pf_size: 'L',
    pf_ordered_qty: 600,
    pf_produced_qty: 590,
    pf_produced_date: '2023-08-03',
    rib_name: 'Rib B',
    rib_ordered_qty: 400,
    rib_produced_qty: 380,
    rib_produced_date: '2023-08-04',
    ip_name: 'IP B',
    ip_ordered_qty: 300,
    ip_cut_qty: 290,
    ip_cut_date: '2023-08-07',
    ip_sew_qty: 285,
    ip_sew_date: '2023-08-11',
    fp_name: 'FP B',
    fp_ordered_qty: 500,
    fp_packed_qty: 500,
    fp_cut_qty: 480,
    fp_cut_date: '2023-08-08',
    fp_sew_qty: 475,
    ip_packed_qty: 460,
    ip_packed_date: '2023-08-13',
  },
  {
    po: 'PO1236',
    lot: 'LOT003',
    pf_name: 'Product C',
    pf_color: 'Green',
    pf_size: 'S',
    pf_ordered_qty: 700,
    pf_produced_qty: 650,
    pf_produced_date: '2023-08-05',
    rib_name: 'Rib C',
    rib_ordered_qty: 500,
    rib_produced_qty: 490,
    rib_produced_date: '2023-08-06',
    ip_name: 'IP C',
    ip_ordered_qty: 400,
    ip_cut_qty: 390,
    ip_cut_date: '2023-08-09',
    ip_sew_qty: 385,
    ip_sew_date: '2023-08-12',
    fp_name: 'FP C',
    fp_ordered_qty: 600,
    fp_packed_qty: 600,
    fp_cut_qty: 580,
    fp_cut_date: '2023-08-10',
    fp_sew_qty: 575,
    ip_packed_qty: 560,
    ip_packed_date: '2023-08-14',
  },
  {
    po: 'PO1237',
    lot: 'LOT004',
    pf_name: 'Product D',
    pf_color: 'Yellow',
    pf_size: 'XL',
    pf_ordered_qty: 800,
    pf_produced_qty: 770,
    pf_produced_date: '2023-08-07',
    rib_name: 'Rib D',
    rib_ordered_qty: 600,
    rib_produced_qty: 590,
    rib_produced_date: '2023-08-08',
    ip_name: 'IP D',
    ip_ordered_qty: 500,
    ip_cut_qty: 490,
    ip_cut_date: '2023-08-11',
    ip_sew_qty: 485,
    ip_sew_date: '2023-08-13',
    fp_name: 'FP D',
    fp_ordered_qty: 700,
    fp_packed_qty: 700,
    fp_cut_qty: 680,
    fp_cut_date: '2023-08-12',
    fp_sew_qty: 675,
    ip_packed_qty: 660,
    ip_packed_date: '2023-08-15',
  },
  {
    po: 'PO1238',
    lot: 'LOT005',
    pf_name: 'Product E',
    pf_color: 'Black',
    pf_size: 'M',
    pf_ordered_qty: 900,
    pf_produced_qty: 850,
    pf_produced_date: '2023-08-09',
    rib_name: 'Rib E',
    rib_ordered_qty: 700,
    rib_produced_qty: 690,
    rib_produced_date: '2023-08-10',
    ip_name: 'IP E',
    ip_ordered_qty: 600,
    ip_cut_qty: 590,
    ip_cut_date: '2023-08-14',
    ip_sew_qty: 585,
    ip_sew_date: '2023-08-16',
    fp_name: 'FP E',
    fp_ordered_qty: 800,
    fp_packed_qty: 800,
    fp_cut_qty: 780,
    fp_cut_date: '2023-08-15',
    fp_sew_qty: 775,
    ip_packed_qty: 760,
    ip_packed_date: '2023-08-17',
  },
  {
    po: 'PO1234',
    lot: 'LOT001',
    pf_name: 'Product A',
    pf_color: 'Red',
    pf_size: 'M',
    pf_ordered_qty: 500,
    pf_produced_qty: 450,
    pf_produced_date: '2023-08-01',
    rib_name: 'Rib A',
    rib_ordered_qty: 300,
    rib_produced_qty: 290,
    rib_produced_date: '2023-08-02',
    ip_name: 'IP A',
    ip_ordered_qty: 200,
    ip_cut_qty: 180,
    ip_cut_date: '2023-08-05',
    ip_sew_qty: 175,
    ip_sew_date: '2023-08-10',
    fp_name: 'FP A',
    fp_ordered_qty: 400,
    fp_packed_qty: 400,
    fp_cut_qty: 350,
    fp_cut_date: '2023-08-06',
    fp_sew_qty: 340,
    ip_packed_qty: 330,
    ip_packed_date: '2023-08-12',
  },
  {
    po: 'PO1235',
    lot: 'LOT002',
    pf_name: 'Product B',
    pf_color: 'Blue',
    pf_size: 'L',
    pf_ordered_qty: 600,
    pf_produced_qty: 590,
    pf_produced_date: '2023-08-03',
    rib_name: 'Rib B',
    rib_ordered_qty: 400,
    rib_produced_qty: 380,
    rib_produced_date: '2023-08-04',
    ip_name: 'IP B',
    ip_ordered_qty: 300,
    ip_cut_qty: 290,
    ip_cut_date: '2023-08-07',
    ip_sew_qty: 285,
    ip_sew_date: '2023-08-11',
    fp_name: 'FP B',
    fp_ordered_qty: 500,
    fp_packed_qty: 500,
    fp_cut_qty: 480,
    fp_cut_date: '2023-08-08',
    fp_sew_qty: 475,
    ip_packed_qty: 460,
    ip_packed_date: '2023-08-13',
  },
  {
    po: 'PO1236',
    lot: 'LOT003',
    pf_name: 'Product C',
    pf_color: 'Green',
    pf_size: 'S',
    pf_ordered_qty: 700,
    pf_produced_qty: 650,
    pf_produced_date: '2023-08-05',
    rib_name: 'Rib C',
    rib_ordered_qty: 500,
    rib_produced_qty: 490,
    rib_produced_date: '2023-08-06',
    ip_name: 'IP C',
    ip_ordered_qty: 400,
    ip_cut_qty: 390,
    ip_cut_date: '2023-08-09',
    ip_sew_qty: 385,
    ip_sew_date: '2023-08-12',
    fp_name: 'FP C',
    fp_ordered_qty: 600,
    fp_packed_qty: 600,
    fp_cut_qty: 580,
    fp_cut_date: '2023-08-10',
    fp_sew_qty: 575,
    ip_packed_qty: 560,
    ip_packed_date: '2023-08-14',
  },
  {
    po: 'PO1237',
    lot: 'LOT004',
    pf_name: 'Product D',
    pf_color: 'Yellow',
    pf_size: 'XL',
    pf_ordered_qty: 800,
    pf_produced_qty: 770,
    pf_produced_date: '2023-08-07',
    rib_name: 'Rib D',
    rib_ordered_qty: 600,
    rib_produced_qty: 590,
    rib_produced_date: '2023-08-08',
    ip_name: 'IP D',
    ip_ordered_qty: 500,
    ip_cut_qty: 490,
    ip_cut_date: '2023-08-11',
    ip_sew_qty: 485,
    ip_sew_date: '2023-08-13',
    fp_name: 'FP D',
    fp_ordered_qty: 700,
    fp_packed_qty: 700,
    fp_cut_qty: 680,
    fp_cut_date: '2023-08-12',
    fp_sew_qty: 675,
    ip_packed_qty: 660,
    ip_packed_date: '2023-08-15',
  },
  {
    po: 'PO1238',
    lot: 'LOT005',
    pf_name: 'Product E',
    pf_color: 'Black',
    pf_size: 'M',
    pf_ordered_qty: 900,
    pf_produced_qty: 850,
    pf_produced_date: '2023-08-09',
    rib_name: 'Rib E',
    rib_ordered_qty: 700,
    rib_produced_qty: 690,
    rib_produced_date: '2023-08-10',
    ip_name: 'IP E',
    ip_ordered_qty: 600,
    ip_cut_qty: 590,
    ip_cut_date: '2023-08-14',
    ip_sew_qty: 585,
    ip_sew_date: '2023-08-16',
    fp_name: 'FP E',
    fp_ordered_qty: 800,
    fp_packed_qty: 800,
    fp_cut_qty: 780,
    fp_cut_date: '2023-08-15',
    fp_sew_qty: 775,
    ip_packed_qty: 760,
    ip_packed_date: '2023-08-17',
  },
  {
    po: 'PO1234',
    lot: 'LOT001',
    pf_name: 'Product A',
    pf_color: 'Red',
    pf_size: 'M',
    pf_ordered_qty: 500,
    pf_produced_qty: 450,
    pf_produced_date: '2023-08-01',
    rib_name: 'Rib A',
    rib_ordered_qty: 300,
    rib_produced_qty: 290,
    rib_produced_date: '2023-08-02',
    ip_name: 'IP A',
    ip_ordered_qty: 200,
    ip_cut_qty: 180,
    ip_cut_date: '2023-08-05',
    ip_sew_qty: 175,
    ip_sew_date: '2023-08-10',
    fp_name: 'FP A',
    fp_ordered_qty: 400,
    fp_packed_qty: 400,
    fp_cut_qty: 350,
    fp_cut_date: '2023-08-06',
    fp_sew_qty: 340,
    ip_packed_qty: 330,
    ip_packed_date: '2023-08-12',
  },
  {
    po: 'PO1235',
    lot: 'LOT002',
    pf_name: 'Product B',
    pf_color: 'Blue',
    pf_size: 'L',
    pf_ordered_qty: 600,
    pf_produced_qty: 590,
    pf_produced_date: '2023-08-03',
    rib_name: 'Rib B',
    rib_ordered_qty: 400,
    rib_produced_qty: 380,
    rib_produced_date: '2023-08-04',
    ip_name: 'IP B',
    ip_ordered_qty: 300,
    ip_cut_qty: 290,
    ip_cut_date: '2023-08-07',
    ip_sew_qty: 285,
    ip_sew_date: '2023-08-11',
    fp_name: 'FP B',
    fp_ordered_qty: 500,
    fp_packed_qty: 500,
    fp_cut_qty: 480,
    fp_cut_date: '2023-08-08',
    fp_sew_qty: 475,
    ip_packed_qty: 460,
    ip_packed_date: '2023-08-13',
  },
  {
    po: 'PO1236',
    lot: 'LOT003',
    pf_name: 'Product C',
    pf_color: 'Green',
    pf_size: 'S',
    pf_ordered_qty: 700,
    pf_produced_qty: 650,
    pf_produced_date: '2023-08-05',
    rib_name: 'Rib C',
    rib_ordered_qty: 500,
    rib_produced_qty: 490,
    rib_produced_date: '2023-08-06',
    ip_name: 'IP C',
    ip_ordered_qty: 400,
    ip_cut_qty: 390,
    ip_cut_date: '2023-08-09',
    ip_sew_qty: 385,
    ip_sew_date: '2023-08-12',
    fp_name: 'FP C',
    fp_ordered_qty: 600,
    fp_packed_qty: 600,
    fp_cut_qty: 580,
    fp_cut_date: '2023-08-10',
    fp_sew_qty: 575,
    ip_packed_qty: 560,
    ip_packed_date: '2023-08-14',
  },
  {
    po: 'PO1237',
    lot: 'LOT004',
    pf_name: 'Product D',
    pf_color: 'Yellow',
    pf_size: 'XL',
    pf_ordered_qty: 800,
    pf_produced_qty: 770,
    pf_produced_date: '2023-08-07',
    rib_name: 'Rib D',
    rib_ordered_qty: 600,
    rib_produced_qty: 590,
    rib_produced_date: '2023-08-08',
    ip_name: 'IP D',
    ip_ordered_qty: 500,
    ip_cut_qty: 490,
    ip_cut_date: '2023-08-11',
    ip_sew_qty: 485,
    ip_sew_date: '2023-08-13',
    fp_name: 'FP D',
    fp_ordered_qty: 700,
    fp_packed_qty: 700,
    fp_cut_qty: 680,
    fp_cut_date: '2023-08-12',
    fp_sew_qty: 675,
    ip_packed_qty: 660,
    ip_packed_date: '2023-08-15',
  },
  {
    po: 'PO1238',
    lot: 'LOT005',
    pf_name: 'Product E',
    pf_color: 'Black',
    pf_size: 'M',
    pf_ordered_qty: 900,
    pf_produced_qty: 850,
    pf_produced_date: '2023-08-09',
    rib_name: 'Rib E',
    rib_ordered_qty: 700,
    rib_produced_qty: 690,
    rib_produced_date: '2023-08-10',
    ip_name: 'IP E',
    ip_ordered_qty: 600,
    ip_cut_qty: 590,
    ip_cut_date: '2023-08-14',
    ip_sew_qty: 585,
    ip_sew_date: '2023-08-16',
    fp_name: 'FP E',
    fp_ordered_qty: 800,
    fp_packed_qty: 800,
    fp_cut_qty: 780,
    fp_cut_date: '2023-08-15',
    fp_sew_qty: 775,
    ip_packed_qty: 760,
    ip_packed_date: '2023-08-17',
  },
];

export const SKU_COLUMN = [
  {
    name: 'SKU',
    classNameTH: 'd-flex justify-content-start px-5 ',
    classNameTD: 'd-flex justify-content-start align-items-center px-5 border ',
    dataField: ['name'],
  },
  {
    name: 'Quantity',
    classNameTH: 'option-th',
    classNameTD: 'option-td border',
    dataField: ['quantity'],
  },
  {
    name: 'Action',
    classNameTH: 'd-flex justify-content-center ',
    classNameTD: 'option-td border',
    isCustom: true,
    dataField: ['id', 'name', 'quantity'],
    customFormatter: ({ id, name, quantity, func }) => {
      return (
        <div className="d-flex justify-content-evenly h-100 align-items-center gap-3">
          <div
            onClick={() => {
              func({ data: { id, name, quantity }, action: 'edit' });
            }}
            className="w-50 h-100 m-0 d-flex align-items-center justify-content-center action-div-3">
            <EditIcon />
          </div>
          <div
            onClick={() => {
              func({ data: { id, name, quantity }, action: 'delete' });
            }}
            className="w-50 h-100 m-0 d-flex align-items-center justify-content-center action-div-3">
            <DeleteRedIcon />
          </div>
        </div>
      );
    },
  },
];
